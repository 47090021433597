<template>
  <div>
    <form  @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class="offset-1 col-5 shadow p-3">
            <p class="text-center p-3">Cari data Penjualan dari S/D tanggal</p>
            <form action="" @submit.prevent="submit">
                <div class="sm-form ">
                    <input type="date" id="tanggal1" name="tanggal1" class="form-control form-control-sm" placeholder="tanggal1" v-model="vdata.tanggal1" >
                </div>
                <div class="sm-form ">
                    <input type="date" id="tanggal2" name="tanggal2" class="form-control form-control-sm" placeholder="tanggal2" v-model="vdata.tanggal2" >
                </div>
                <hr>
                <button type="submit" class="btn btn-sm btn-dark  ">Cari</button>
            </form>
          <!-- END BUTTON PART -->
        </div>
      </div>
    </form>
    <!-- TABLE PART -->
    <div v-if="datanya.length>0" class="p-3">
         <vue-json-excel :data="datanya">
              <button type="button" class="btn btn-sm btn-dark">
                Download Data <span class="typcn typcn-chart-area"></span>
              </button>
            </vue-json-excel>
        <br>
      <Btables :tables="datanya"  :cari="['']" :hide="['']" @selected="ambil" ref="btable" />
      <p class="text-2xl font-bold text-right">TOTAL : Rp. {{total}}</p>
    </div>
    <br>
    <br>
    <br>
    <br>
    <!-- END TABLE PART -->
  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
export default {
  components: {
    Btables,
  },
  layout:'app_shop_admin',
  data() {
    return {
      selected: false,
      vdata: {},
      btn: "tambah",
      datanya:[],
      total:0
    };
  },
  methods: {
      excel(){
          
      },
      format(tanggal) {
          console.log(tanggal)
            return this.$datefns.format(
              new Date(tanggal),
              "DD MMMM YYYY/HH-mm"
            );
      },
    getData(){
      let that=this;
      sdb.collection("app_kasir_keranjang2",false).doc().select(`select tb1.nota,tb1.tanggal,tb2.barcode,tb2.nama_produk,tb2.satuan,tb1.harga,tb1.jumlah,tb1.diskon,tb1.total as sub_total from app_kasir_keranjang2 tb1 
left join app_kasir_produk tb2 on tb2.id=tb1.id_produk      
WHERE tb1.tanggal_w >= '${this.vdata.tanggal1}' AND tb1.tanggal_w <= '${this.vdata.tanggal2}'`).then(res => {
        this.datanya=res;
        this.datanya.forEach((e,i)=>{
          e.tanggal=that.format(e.tanggal)
        })
        this.total =this.datanya.reduce((e,n)=>{
          return e+parseInt(n.sub_total)
        },0)
        this.$forceUpdate();
      });
      
    },
    ambil(data) {
      this.vdata = data;
      this.btn = "update";
      this.$forceUpdate();
    },
    submit() {
     this.getData();
    },
  },
  async mounted() {
  },
};
</script>